import Rails from "@rails/ujs";
Rails.start();

import axios from 'axios';

import "bootstrap";

// Importar outros scripts
import "./multiselect";
import "./order-show";
  

import "trix"
import "@rails/actiontext"
