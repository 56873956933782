document.addEventListener("DOMContentLoaded", () => {
  const form = document.querySelector("#order-review-form");
  if (!form) return;

  const cepElement = document.querySelector("#cep-value");
  const cepSpan = document.querySelector("#order-cep-return");

  if (cepElement) {
    const url = `https://viacep.com.br/ws/${cepElement.innerText}/json/`;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        cepSpan.innerText = data.logradouro ? `${data.logradouro}, ${data.bairro}, ${data.localidade} - ${data.uf}` : "CEP Inválido";
      });
  }

  const editBtns = document.querySelectorAll(".edit-btn");
  const orderUrl = `/api/v1/admin/orders/${form.dataset.orderId}`;

  editBtns.forEach((btn) => {
    btn.addEventListener("click", async () => {
      const elementParent = btn.parentNode.parentNode;

      const fieldName = elementParent.dataset.column;

      const fieldInput =
        btn.parentNode.parentNode.querySelector(".field-input");

      const fieldValue = fieldInput.value;

      const updateObject = {};
      updateObject.order = {};
      updateObject.order[fieldName] = fieldValue;

      const response = await axios.patch(orderUrl, updateObject);

      if (response.data.success) {
        if (elementParent.dataset.displayField) {
          elementParent.querySelector(".field-value").innerHTML =
            response.data[elementParent.dataset.displayField] || "-";
        } else {
          elementParent.querySelector(".field-value").innerHTML =
            response.data[fieldName] || "-";
        }

        fieldInput.value = "";
        Swal.fire({
          title: "Sucesso!",
          text: "Campo atualizado",
          icon: "success",
          confirmButtonText: "Ok",
        });
      } else {
        Swal.fire({
          title: "Erro!",
          text: JSON.stringify(response.data.errors),
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    });
  });

  document.querySelectorAll(".dropzone-order").forEach((dropzone) => {
    new Dropzone(dropzone, {
      url: `/api/v1/admin/orders/${dropzone.dataset.orderId}`,
      method: "put",
      init: function () {
        this.on("sending", (file, xhr, formData) => {
          formData.append(`order[${dropzone.dataset.field}]`, file);
        });
        this.on("addedfile", function (file) {
          if (
            !confirm(
              "Tem certeza que deseja enviar o arquivo? Isso apagará o arquivo atual."
            )
          ) {
            this.removeFile(file);
            return false;
          }
        });
        this.on("success", function (file, response) {
          if (response.success) {
            const backgroundEl =
              dropzone.parentNode.querySelector(".background-photo-modal");
            const fileUploadUrl = response[dropzone.dataset.field + "_url"];
            backgroundEl.style = `height: 400px; background-image: url(${fileUploadUrl});`;
            document.querySelector(`#iframe_${dropzone.dataset.field}`).src =
              `${fileUploadUrl}#view=fitH`;
            this.removeAllFiles(true);
            Swal.fire({
              title: "Sucesso!",
              text: "Imagem atualizada",
              icon: "success",
              confirmButtonText: "Ok",
            });
          } else {
            Swal.fire({
              title: "Erro",
              text: JSON.stringify(response.errors),
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
        });
      },
    });
  });
});
