document.addEventListener("DOMContentLoaded", () => {
  const selectableFields = document.querySelectorAll(".selectable");

  const toggleInputElement = (field) => {
    const currentInputForSelectableField = document.querySelector(
      `input[name="${field.dataset.target}"][value="${field.dataset.value}"]`
    );

    if (currentInputForSelectableField) {
      currentInputForSelectableField.remove();
      field.classList.remove("selected");
    } else {
      const formElement = field.closest("form");
      formElement.insertAdjacentHTML(
        "afterbegin",
        `<input type="hidden" name="${field.dataset.target}" value="${field.dataset.value}"></input>`
      );
      field.classList.add("selected");
    }
  };

  selectableFields.forEach((field) => {
    field.addEventListener("click", () => {
      toggleInputElement(field);
    });

    if (field.classList.contains("selected")) toggleInputElement(field);
  });
});

